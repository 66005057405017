import { createSlice } from "@reduxjs/toolkit"
import api from '../../services/api.service';
import utils from "../utils";
import { URL_API, URL_PUBLICA } from "../../constants/constants";

const initialState = {
    cities: [],
    states: utils.getStates(),
    banner: null,
    professions: [],
    errorMessage: "",
    successMessage: "",
}

const BannerSlice = createSlice({
    name: "bannerState",
    initialState,
    reducers: {
        setCities: (state, { payload }) => {
            const cities = payload;
            return { ...state, cities }
        },
        setBanner(state, { payload }) {
            const banner = payload;
            return { ...state, banner }
        },
        setProfessions(state, { payload }) {
            const professions = payload;
            return { ...state, professions }
        },
        setErrorMessage(state, { payload }) {
            const errorMessage = payload;
            return { ...state, errorMessage };
        },
        setSuccessMessage(state, { payload }) {
            const successMessage = payload;
            return { ...state, successMessage };
        },
    }
});

export const {
    setCities,
    setBanner,
    setProfessions,
    setErrorMessage,
    setSuccessMessage,

} = BannerSlice.actions;


export const loadProfessions = () => (dispatch) => {

    return fetch(`${URL_PUBLICA}/professions/all`, {
        method: 'GET',
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch(setProfessions(data));
        })
        .catch((error) => {
            console.log({ error })
        });

}

export const loadCities = (state) => (dispatch) => {
    try {
        const data = {
            uf: state
        }
        return fetch(`${URL_PUBLICA}/cities-by-state`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(setCities(data));

            })
    } catch (error) {
        console.log({ error })
    }
}

export const sendDataForm = (data) => async (dispatch) => {
    try {

        const {
            file,
            cityId,
            accessLink,
            professions,
        } = data;

        // console.log({ file });

        //headers
        let headers = api.getHeaders();
        delete headers['Content-Type']; // Remove o cabeçalho 'Content-Type'


        // Enviar o FormData para o servidor
        const URI = `${URL_API}/banner`;

        const formData = new FormData();
        formData.append("cityId", parseInt(cityId));
        formData.append("accessLink", accessLink);
        formData.append("file", file[0]);  // Adiciona o arquivo diretamente ao FormData
        professions.forEach(profession => formData.append("professions", profession));



        return fetch(URI, {
            method: 'POST',
            headers,
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log({ data })

                dispatch(setBanner(data));
                dispatch(setSuccessMessage("Cadastro realizado com sucesso."));

            })

    } catch (error) {
        console.log({ error })
        dispatch(setErrorMessage("Ops, houve um erro ao realizar o cadastro do banner."));
    }
}


export default BannerSlice.reducer;
