import { styled } from '@mui/system';
import { Alert, Stack } from "@mui/material";


const useStyles = styled((theme) => ({
  container: {
    width: '100%',
    alignItems: 'center',
  }
}));

export default function MakeMessage(props) {
  return (
    <Stack spacing={2} sx={{ marginBottom: '20px' }}>
      <Alert variant="filled" severity={props.typeMessage} onClose={props.onClose}>
        {props.message}
      </Alert>
    </Stack>
  )
}