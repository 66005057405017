import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, TextField, Typography, FormControl, FormControlLabel, Checkbox, Grid, MenuItem, InputLabel, Select, InputAdornment, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import BasePage from '../../../../components/page_base/PageBase';
import LGPDModal from '../../../../components/modal/LGPDModal';
import MakeMessage from '../../../../components/makeMessage/MakeMessage';

import { loadCities, loadProfessions, save, setErrorMessage, setSuccessMessage, loadProfessionalByID } from '../../professional.reducer';
import theme from '../theme';
import utils from '../../../utils';

const FormProfessional = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams(); // Pegue o ID da URL 

    const professionalState = useSelector((state) => state.professionalState);
    const professional = professionalState.professional ? professionalState.professional : null;


    const [uf, setUf] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [cityId, setCityId] = useState('');
    const [loading, setLoading] = useState(true);
    const [password, setPassword] = useState('');
    const [telephone, setTelephone] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [lgpdAceito, setLgpdAceito] = useState('não');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [professionsSelected, setProfessionsSelected] = useState([]);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    useEffect(() => {
        dispatch(loadProfessions());
        if (id) {
            dispatch(loadProfessionalByID(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (professional && id) {
            const { nome, email, telefone, lgpdAceito, cidade, profissoes, password } = professional;
            setName(nome);
            setEmail(email);
            setTelephone(telefone);
            setLgpdAceito(lgpdAceito);
            if (cidade) {
                setCityId(cidade?.oid || ''); // Use operador de coalescência nula para acessar cidade.oid
                setUf(cidade?.uf || ''); // Use operador de coalescência nula para acessar cidade.uf
                const selectedProfessionIds = profissoes.map(profissao => profissao.oid);
                setProfessionsSelected(selectedProfessionIds);
                dispatch(loadCities(cidade.uf)); // Verifique se cidade.uf está definido antes de passar para loadCities
            }
        }
    }, [professional, id, dispatch]);

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleModalAgree = () => {
        setLgpdAceito('sim');
        setModalOpen(false);
        dispatch(setErrorMessage(""));
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    }

    const handleTelephoneChange = (event) => {
        setTelephone(utils.formatPhoneNumber(event.target.value));
    };

    const onChangeState = (e) => {
        setUf(e.target.value);
        dispatch(loadCities(e.target.value));
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onCloseMessage = () => {
        dispatch(setErrorMessage(""));
        dispatch(setSuccessMessage(""));
    };

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const validateForm = () => {
        if (!name || !email || !telephone || !cityId || !password || lgpdAceito === 'não' || professionsSelected.length === 0) {
            return false;
        }
    };

    const onSubmitForm = () => {
        if (!validateForm()) {
            if (lgpdAceito === 'não') {
                dispatch(setErrorMessage("Você não aceitou o termo LGPD"));
            } else if (password !== confirmPassword) {
                setErrorModalOpen(true);
            } else {
                setLoading(true);
                // console.log({ id, name, email, telephone, cityId, lgpdAceito, password, professionIds: professionsSelected });
                dispatch(save({ id, name, email, telephone, cityId, lgpdAceito, password, professionIds: professionsSelected }))
                    .then(() => {
                        setLoading(false);
                        setLgpdAceito('não');
                    })
                    .catch((error) => {
                        setLoading(false);
                    });
            }
        } else {
            dispatch(setErrorMessage("Todos os campos são obrigatórios."));
        }
    };

    const handleProfessionChange = (id) => {
        setProfessionsSelected((prev) => {
            if (prev.includes(id)) {
                return prev.filter((selectedId) => selectedId !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    const page = (
        <>
            <Button onClick={() => navigate(-1)} variant="outlined" color="primary" sx={{ marginBottom: 2 }}>
                Voltar
            </Button>

            <Box className="heading_container" mb={3} >
                <Typography variant="h4" align="center" >
                    {id ? "Editar Profissional" : "Cadastrar Profissional"}
                </Typography>
            </Box>

            {professionalState.errorMessage && (
                <MakeMessage
                    typeMessage="error"
                    message={professionalState.errorMessage}
                    onClose={onCloseMessage}
                />
            )}

            {professionalState.successMessage && (
                <MakeMessage
                    typeMessage="success"
                    message={professionalState.successMessage}
                    onClose={onCloseMessage}
                />
            )}

            {!id &&
                <Typography variant="body1" color={'red'} gutterBottom>
                    Para realizar seu cadastro, leia o termo de aceitação - LGPD.
                </Typography>
            }

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} mb={8}>
                <TextField
                    fullWidth
                    name="nome"
                    label="Nome"
                    variant="outlined"
                    value={name}
                    onChange={handleNameChange}
                    required
                />
                <TextField
                    fullWidth
                    name="email"
                    label="E-mail"
                    variant="outlined"
                    value={email}
                    onChange={handleEmailChange}
                    required
                />
                <TextField
                    fullWidth
                    name="telephone"
                    label="Telefone Comercial"
                    variant="outlined"
                    value={telephone}
                    onChange={handleTelephoneChange}
                    required
                />
                {!id &&
                    <>

                        <TextField
                            fullWidth
                            type={showPassword ? "text" : "password"}
                            name="senha"
                            label="Senha de acesso para plataforma"
                            variant="outlined"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleTogglePasswordVisibility}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            fullWidth
                            type={showPassword ? "text" : "password"}
                            name="confirmarSenha"
                            label="Confirmar Senha"
                            variant="outlined"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleTogglePasswordVisibility}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </>
                }
                <FormControl fullWidth required>
                    <InputLabel id="estado-label">Estado</InputLabel>
                    <Select
                        labelId="estado-label"
                        value={uf}
                        onChange={onChangeState}
                        label="Estado"
                        name="uf"
                    >
                        <MenuItem value="">
                            <em>Estado</em>
                        </MenuItem>
                        {professionalState.states && professionalState.states.map((state, index) => (
                            <MenuItem key={index + 1} value={state.name}>
                                {state.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth required>
                    <InputLabel id="cidade-label" >Cidade</InputLabel>
                    <Select
                        labelId="cidade-label"
                        value={cityId}
                        onChange={(e) => setCityId(e.target.value)}
                        label="Cidade"
                        name="cidadeId"
                    >
                        <MenuItem value="">
                            <em>Cidade</em>
                        </MenuItem>
                        {professionalState.cities && professionalState.cities.map((city, index) => (
                            <MenuItem key={index} value={city.id}>
                                {city.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Box sx={{ mt: 2, p: 2, border: '1px solid #ccc', borderRadius: 2 }}>

                    <FormControl fullWidth required component="fieldset">
                        <Typography variant="body1">Profissões</Typography>
                        <Grid container spacing={2}>
                            {professionalState.professions && professionalState.professions.map((profession) => (
                                <Grid item xs={12} sm={6} md={4} key={profession.id}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                key={profession.id}
                                                checked={professionsSelected.includes(profession.id)}
                                                onChange={() => handleProfessionChange(profession.id)}
                                                name={profession.name}
                                            />
                                        }
                                        label={profession.name}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </FormControl>
                </Box>

                {!id &&
                    <Box>
                        <Button variant="text" onClick={() => setModalOpen(true)}>
                            Ler termo de aceitação - LGPD
                        </Button>
                        <input type="hidden" id="lgpdAceito" name="lgpdAceito" value={lgpdAceito} />
                    </Box>
                }
                <Button type="submit" variant="contained" color="primary" onClick={onSubmitForm} >
                    {id ? "Salvar" : "Cadastrar"}
                </Button>

                <Dialog open={errorModalOpen} onClose={handleErrorModalClose}>
                    <DialogTitle>Erro</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            As senhas não coincidem. Por favor, verifique.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleErrorModalClose} color="primary">
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>

            <LGPDModal open={modalOpen} onClose={handleModalClose} onAgree={handleModalAgree} />
        </>
    );

    return <BasePage theme={theme} loading={loading}>
        {page}
    </BasePage>;
};

export default FormProfessional;
