import { createSlice } from "@reduxjs/toolkit";
import api from "../../services/api.service";
import storage from "../../store/storage";
import { URL_API } from "../../constants/constants";



const initialState = {
    budgets: [],
    errorMessage: "",
    successMessage: "",
}

const BudgetSlice = createSlice({
    name: "budgetState",
    initialState,
    reducers: {
        setBudgets(state, { payload }) {
            const budgets = payload;
            return { ...state, budgets };
        },
        setErrorMessage(state, { payload }) {
            const errorMessage = payload;
            return { ...state, errorMessage };
        },
        setSuccessMessage(state, { payload }) {
            const successMessage = payload;
            return { ...state, successMessage };
        },
    }
});

export const {
    setBudgets,
    setErrorMessage,
    setSuccessMessage,
} = BudgetSlice.actions;

export const loadBudgetByMonth = (month, page = 1, pagesize = 10) => (dispatch) => {
    try {
        const user = storage.getUser();
        const data = {
            month,
            page,
            pagesize,
            professionalID: user.id,
        }

        const URI = `${URL_API}/budgets/month`;
        return fetch(URI, {
            method: 'POST',
            headers: api.getHeaders(),
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log({ data });
                dispatch(setBudgets(data));
            })
            .catch((error) => {
                console.log({ error });
                dispatch(setBudgets([]));
                dispatch(setErrorMessage("Ops, Houve um erro ao realizar a pesquisa dos orçamentos."));
            })
    } catch (error) {
        console.log({ error })
    }
}

export default BudgetSlice.reducer;