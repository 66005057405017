
import { URL_BASE } from '../constants/constants';
import storage from '../store/storage';

const prefix = `${URL_BASE}/api/v1`;

const getHeaders = () => {
    const headers = {
        'Content-Type': 'application/json',
    };
    const token = storage.getToken();
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    return headers;
};

const handleResponse = (response) => {
    if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
    }
    return response.json();
};

const handleError = (error) => {
    console.error('Request failed', error);
    throw error;
};

const get = (path) => {
    const PATH = `${prefix}${path}`;
    return fetch(PATH, { headers: getHeaders() })
        .then(handleResponse)
        .catch(handleError);
};

const post = (path, data = {}) => {
    const PATH = `${prefix}${path}`;
    return fetch(PATH, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data)
    })
        .then(handleResponse)
        .catch(handleError);
};

const put = (path, data) => {
    const PATH = `${prefix}${path}`;
    return fetch(PATH, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(data)
    })
        .then(handleResponse)
        .catch(handleError);
};

const remove = (path) => {
    const PATH = `${prefix}${path}`;
    return fetch(PATH, {
        method: 'DELETE',
        headers: getHeaders()
    })
        .then(handleResponse)
        .catch(handleError);
};



export default {
    get,
    post,
    put,
    remove,
    getHeaders,
};
