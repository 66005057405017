import { Box } from "@mui/material";
import Carousel from "react-material-ui-carousel";

const Carrossel = ({ carouselItems }) => {

    return (
        <Box sx={{
            width: '99vw',
            position: 'relative',
            left: '50%',
            right: '50%',
            marginLeft: '-50vw',
            marginRight: '-50vw',
            overflow: 'hidden'
        }}>
            <Carousel
                sx={{ width: '100vw', height: 'auto' }}
                indicators={false}
                navButtonsAlwaysVisible={true}
            >
                {carouselItems.map((item, index) => (
                    <Box key={index} sx={{ textAlign: 'center', width: '100%' }}>
                        <img
                            src={item.img}
                            alt={item.title}
                            loading="lazy"
                            style={{
                                width: '99%',
                                height: '100%',
                                objectFit: 'cover',
                                boxShadow: '30px',
                                borderRadius: '50px'
                            }}
                        />
                    </Box>
                ))}
            </Carousel>
        </Box>
    )
};

export default Carrossel;
