import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, TextField, Typography, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TablePagination, Paper, InputAdornment, IconButton } from '@mui/material';


import theme from './theme';
import BasePage from '../../../components/page_base/PageBase';

const MyCashback = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5); // Quantidade de linhas por página

    // Dados de exemplo de cashback para um profissional específico
    const professionalCashback = [
        { id: 1, month: 'Janeiro', cashback: 200.5 },
        { id: 2, month: 'Fevereiro', cashback: 150.75 },
        { id: 3, month: 'Março', cashback: 80.2 },
        { id: 4, month: 'Abril', cashback: 120.0 },
        { id: 5, month: 'Maio', cashback: 90.6 },
        { id: 6, month: 'Junho', cashback: 180.3 },
        { id: 7, month: 'Julho', cashback: 110.9 },
        { id: 8, month: 'Agosto', cashback: 220.0 },
        { id: 9, month: 'Setembro', cashback: 300.4 },
        { id: 10, month: 'Outubro', cashback: 95.2 },
    ];

    const filteredCashback = professionalCashback.filter(entry =>
        entry.month.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setPage(0); // Voltar para a primeira página ao pesquisar
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Voltar para a primeira página ao alterar o número de linhas por página
    };

    const pageData = filteredCashback.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleBack = () => {
        navigate(-1);
    };

    const pageContent = (
        <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <IconButton onClick={handleBack} sx={{ mr: 2 }}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h4" gutterBottom>
                    Cashback do Profissional
                </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Pesquisar por mês"
                    value={searchQuery}
                    onChange={handleSearch}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Mês</TableCell>
                            <TableCell>Cashback</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pageData.map((entry) => (
                            <TableRow key={entry.id}>
                                <TableCell>{entry.id}</TableCell>
                                <TableCell>{entry.month}</TableCell>
                                <TableCell>{entry.cashback}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={filteredCashback.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );

    return (
        <BasePage
            theme={theme}
            loading={loading}
        >
            {pageContent}
        </BasePage>
    );
};

export default MyCashback;
