import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2', // Cor primária
        },
    },
    typography: {
        h4: {
            fontSize: '2rem', // Tamanho da fonte para o título h4
        },
    },
    components: {
        MuiBox: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                },
            },
        },
    },
});

export default theme;