import React, { useEffect, useState } from 'react';

import { AccountCircle, MonetizationOn, Receipt } from '@mui/icons-material';
import { Container, Box, Typography, Grid, Card, CardContent, CardActionArea, CardActions, Button } from '@mui/material';

import BasePage from '../../components/page_base/PageBase';

import theme from './theme';
import storage from '../../store/storage';

const DashboardUserPanel = () => {
    const [loading, setLoading] = useState(true);

    const user = storage.getUser();

    useEffect(() => {
        setLoading(false);
    }, []);

    const sections = [
        {
            title: "Perfil",
            icon: <AccountCircle style={{ fontSize: 50, color: theme.palette.primary.main }} />,
            description: "Gerencie seu perfil e atualize suas informações.",
            href: `/edit-professional/${user.id}`
        },
        {
            title: "Orçamentos",
            icon: <Receipt style={{ fontSize: 50, color: theme.palette.primary.main }} />,
            description: "Visualize e gerencie seus orçamentos.",
            href: "/my-budgets"
        },
        {
            title: "Cashback",
            icon: <MonetizationOn style={{ fontSize: 50, color: theme.palette.primary.main }} />,
            description: "Veja seu saldo de cashback e histórico.",
            // href: "/my-cashback"
            href: "#"
        }
    ];

    const page = (
        <Container sx={{ mb: 6 }}>
            <Box sx={{ textAlign: 'center', mb: 4 }}>
                <Typography variant="h4">Bem-vindo ao Painel do Profissional</Typography>
                <Typography variant="subtitle1">
                    Selecione uma das opções abaixo para navegar.
                </Typography>
            </Box>
            <Grid container spacing={4}>
                {sections.map((section, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardActionArea href={section.href}>
                                <CardContent sx={{ textAlign: 'center' }}>
                                    <Box sx={{ mb: 2 }}>{section.icon}</Box>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {section.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {section.description}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions sx={{ justifyContent: 'center' }}>
                                <Button size="small" color="primary" href={section.href}>
                                    Acessar
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );

    return (
        <BasePage
            theme={theme}
            loading={loading}
        >
            {page}
        </BasePage>
    );
};

export default DashboardUserPanel;
