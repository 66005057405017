export const URL_BASE = process.env.URL_BASE || "http://localhost:5000";

// descomentar para rodar LOCALHOST
// export const URL_API = process.env.URL_API || `${URL_BASE}/api/v1`;
// export const URL_PUBLICA = process.env.URL_PUBLICA || `${URL_BASE}/publica`;


// descomentar para eviar ao SERVIDOR
export const URL_API = `/api/v1`;
export const URL_PUBLICA = `/publica`;
