
import { useEffect, useState } from 'react';

import theme from './theme';

import BasePage from '../../../components/page_base/PageBase';
import Carrossel from '../../../components/carrossel/Carrosel';
import ImageWithText from '../../../components/image-with-text/ImageWithText';
import ListSixProfessions from '../../../components/list-professions/ListProfessions';

import AnuncieAquiAlta from '../../../assets/ANUNCIE_AQUI_BAIXA.jpeg';
import EncontreProfissionalAlta from '../../../assets/AQUI_VOCE_ENCONTRA_BAIXA.jpeg';
import pedreiro from '../../../assets/pedreiro.jpeg';
import pintor from '../../../assets/pintor.jpeg';
import serralheiro from '../../../assets/serralheiro.jpeg';
import encanador from '../../../assets/encanador.jpeg';
import carpinteiro from '../../../assets/carpinteiro.jpeg';
import eletricista from '../../../assets/eletricista.jpeg';


const HomePage = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, [setLoading]);

    const carouselItems = [
        {
            img: AnuncieAquiAlta,
            title: 'Anuncie sua empresa conosco',
            description: 'Anuncie sua empresa conosco',
        },
        {
            img: EncontreProfissionalAlta,
            title: 'Encontre o profissional que você precisa',
            description: 'Encontre o profissional que você precisa',
        },
    ];

    const professions = [
        { imageUrl: pedreiro, name: 'Pedreiro' },
        { imageUrl: pintor, name: 'Pintor' },
        { imageUrl: serralheiro, name: 'Serralheiro' },
        { imageUrl: encanador, name: 'Encanador' },
        { imageUrl: carpinteiro, name: 'Carpinteiro' },
        { imageUrl: eletricista, name: 'Eletricísta' },
    ];

    const page = (
        <>
            <Carrossel carouselItems={carouselItems} />

            <ImageWithText
                imageUrl="./images/professional-img.png"
                title="Encontre o Profissional"
                text={`"Construir sem aperto,
                Procurar profissionais, é nosso acerto.
                Na construção, economia é fato,
                Construir mais barato, nosso contrato."
                A sua satisfação, é nosso contrato!`}
                link="/search-professionals"
                text_botton="Pesquisar Profissionais"
            />

            <ImageWithText
                imageUrl="./images/about-img.jpg"
                title="Seja reconhecido e esteja à frente."
                text={`Destaque-se na indústria da construção! 
                Cadastre-se em nossa plataforma inovadora e 
                seja visto por uma variedade de oportunidades 
                de negócios e projetos desafiadores. 
                Seja reconhecido como um líder em sua área, 
                conecte-se e expanda suas oportunidades de negócios. 
                Cadastre-se hoje e abra portas para o sucesso na construção! 
                Lembre-se : Quem não é visto, não é lembrado!`}
                link="/add-professionals"
                text_botton="Cadastre-se"
            />

            <ListSixProfessions
                title='Profissões'
                data={professions}
            />

            {/* <BoxWithText
                link='/login'
                text='Testando Box com texto'
                textButton='Texto do botão'
                title='Box com Texto'

            /> */}
        </>
    )

    return (
        <BasePage
            theme={theme}
            loading={loading}
        >
            {page}
        </BasePage>
    );
};

export default HomePage;
