import { Key } from "@mui/icons-material";
import { Box } from "@mui/material";
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';

const Carrossel = ({ carouselItems, isMobile }) => {

    return (
        <Box sx={{
            width: '100vw',
            height: isMobile ? '50vh' : '70vh',
            position: 'relative',
            left: '50%',
            right: '50%',
            marginLeft: '-50vw',
            marginRight: '-50vw',
            overflow: 'hidden',
            display: 'flex', // Usando flexbox para centralizar
            alignItems: 'center', // Centraliza verticalmente
            justifyContent: 'center' // Centraliza horizontalmente
        }}>
            <Carousel style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>

                {carouselItems.map((item, index) => (
                    <Carousel.Item key={index} >
                        <img
                            src={item.img}
                            alt={item.title}
                            style={{
                                width: isMobile ? '96%' : '99%',
                                height: isMobile ? '50vh' : '75vh',
                                objectFit: 'fill',
                                boxShadow: '20px',
                                borderRadius: '20px',
                                overflow: 'hidden',
                            }}
                        />
                    </Carousel.Item>
                )
                )}
            </Carousel>
        </Box >

    )
};

export default Carrossel;
