import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { Search as SearchIcon, AttachMoney as AttachMoneyIcon } from '@mui/icons-material';
import { Grid, Typography, Select, MenuItem, Button, FormControl, InputLabel, Box, TextField, InputAdornment, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Pagination, Paper, CircularProgress } from '@mui/material';

import {
  loadCities,
  clearBanners,
  setCurrentPage,
  loadProfessions,
  clearProfessions,
  loadProfessionals,
  findBannerByCityAndProfession,
  loadProfessionalsByNameAndCityAndProfession,
} from '../search.reducer';

import QuoteModal from '../components/QuoteModal';
import BasePage from '../../../components/page_base/PageBase';

import theme from './theme';


const Search = () => {
  const searchState = useSelector((state) => state.searchState);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState('');
  const [cityId, setCityId] = useState('');
  const [profName, setProfName] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [professionId, setProfessionId] = useState('');
  const [professionNameURL, setProfessionNameURL] = useState('');
  const [selectedProfessionalIds, setSelectedProfessionalIds] = useState([]);
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  // const currentPage = searchState.currentPage || 1;
  // const pageSize = searchState.pageSize || 50;

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  useEffect(() => {
    dispatch(loadProfessions()).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const professionName = searchParams.get('profession');
    setProfessionNameURL(professionName);

    if (professionName) {
      setProfName(professionName);
    }

    const profession = searchState.professions.find((profession) => profession.name === professionName);
    if (profession) {
      setProfessionId(profession.id);
    }
  }, [location.search, searchState.professions]);

  useEffect(() => {
    if (state) {
      dispatch(loadCities(state));
    }
  }, [state, dispatch]);

  useEffect(() => {
    if (professionId) {
      const profession = searchState.professions.find((profession) => profession.id === professionId);
      if (profession) {
        setProfName(profession.name);
      }
    }
  }, [professionId, searchState.professions]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // Verifica se o banner deve estar visível
      setIsBannerVisible(scrollY + windowHeight < documentHeight - 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onSubmitForm = () => {
    setLoadingTable(true);
    dispatch(loadProfessionals(cityId, professionId, searchState.currentPage, searchState.pageSize)).then(() => {
      dispatch(findBannerByCityAndProfession(cityId, professionId));
      setLoadingTable(false);
    });
    setShowForm(false);
  }

  const onNewSearch = () => {
    dispatch(clearProfessions());
    dispatch(clearBanners());
    setState('');
    setCityId('');
    setProfessionId('');
    setShowForm(true);
    setProfName('');
  }

  const handlePageChange = (event, value) => {
    dispatch(setCurrentPage(value));
    dispatch(loadProfessionals(cityId, professionId, value, searchState.pageSize));
  };

  const onHandlerSearch = (e) => {
    setLoading(true);
    const data = {
      cityId,
      professionId,
      searchTerm,
      currentPage: searchState.currentPage,
      pageSize: searchState.pageSize,

    }
    dispatch(loadProfessionalsByNameAndCityAndProfession(data)).then(() => {
      setLoading(false);
    })

  }

  const handleSendQuote = () => {
    const professionalIds = searchState.professionals && searchState.professionals.map(professional => professional.oid);
    setSelectedProfessionalIds(professionalIds);
    setOpenModal(true);
  };

  const handleSendQuoteForOne = (professionalId) => {
    setSelectedProfessionalIds([professionalId]);
    setOpenModal(true);
  };


  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };


  const page = (
    <>
      {showForm && (
        <>
          <Button onClick={() => navigate(-1)} variant="outlined" color="primary" sx={{ marginBottom: 2 }}>
            Voltar
          </Button>
          <Grid container spacing={3} marginBottom={6}>
            <Grid item xs={12} md={8}>
              <Box className="heading_container" mb={3}>
                <Typography variant="h2">Pesquisar Profissional</Typography>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Estado</InputLabel>
                    <Select
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      label="Estado"
                    >
                      <MenuItem value=""><em>Estado</em></MenuItem>
                      {searchState.states && searchState.states.map((state, index) => (
                        <MenuItem key={index} value={state.name}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Cidade</InputLabel>
                    <Select
                      value={cityId}
                      onChange={(e) => setCityId(e.target.value)}
                      label="Cidade"
                      disabled={!state}
                    >
                      <MenuItem value=""><em>Cidade</em></MenuItem>
                      {searchState.cities && searchState.cities.map((city) => (
                        <MenuItem key={city.id} value={city.id}>
                          {city.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth required>
                    <InputLabel>Profissão</InputLabel>
                    <Select
                      value={professionId}
                      onChange={(e) => setProfessionId(e.target.value)}
                      label="Profissão"
                    >
                      <MenuItem value=""><em>Profissão</em></MenuItem>
                      {searchState.professions && searchState.professions.map((profession) => (
                        <MenuItem key={profession.id} value={profession.id} selected={profession.name === professionNameURL}>
                          {profession.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={onSubmitForm}
                    disabled={!state || !cityId || !professionId}
                  >
                    Pesquisar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {!showForm && (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={onNewSearch}
            >
              <SearchIcon sx={{ marginRight: 1 }} />
              Nova Pesquisa
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSendQuote}
              sx={{ backgroundColor: 'red' }}
              disabled={!searchState.professionals.length > 0}
            >
              <AttachMoneyIcon sx={{ marginRight: 1 }} />
              Solicitar Orçamento para todos
            </Button>
          </Box>
        </>
      )}
      {loadingTable ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {!showForm && (
            <>
              {searchState.professionals && searchState.professionals.length > 0 ? (
                <>
                  <Box display="flex" justifyContent="space-between" alignItems="center" boxShadow={3} borderRadius={5} p={2} mb={2} style={{ backgroundColor: 'white' }}>
                    <TextField
                      label="Pesquisar pelo nome"
                      variant="outlined"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button variant="contained" color="primary" onClick={onHandlerSearch} disabled={!searchTerm}>
                              <SearchIcon />
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                      style={{ flexGrow: 1, marginRight: '16px' }}
                    />
                  </Box>
                  <Typography variant="h5" align="center" gutterBottom>
                    {profName}
                  </Typography>

                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead variant="head" style={{ backgroundColor: '#1976d2', color: 'white' }}>
                        <TableRow>
                          <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Nome</TableCell>
                          <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Estado/Cidade</TableCell>
                          <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }} align='center'>Ação</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {searchState.professionals.map((professional, index) => (
                          <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                            <TableCell>{professional.nome}</TableCell>
                            <TableCell>{professional.cidade.uf} - {professional.cidade.nome}</TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleSendQuoteForOne(professional.oid)}
                                style={{ fontSize: '10px', width: '70%' }}
                              >
                                Orçamento
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={Math.ceil(searchState.total / searchState.pageSize)}
                      page={searchState.currentPage}
                      onChange={handlePageChange}
                      color="primary"
                      style={{ marginTop: 16, marginBottom: 16 }}
                    />
                  </TableContainer>
                </>
              ) : (
                <Table>
                  <TableHead variant="head" style={{ backgroundColor: '#1976d2', color: 'white' }}>
                    <TableRow>
                      <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Nome</TableCell>
                      <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Estado/Cidade</TableCell>
                      <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }} align='center'>Ação</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        <Typography variant="h5">Nenhum profissional cadastrado!</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </>
          )}


          <QuoteModal
            open={openModal}
            professionalsId={selectedProfessionalIds}
            onClose={handleCloseModal}
            errorMessage={searchState.errorMessage}
            successMessage={searchState.successMessage}
          />
          {isBannerVisible && (

            searchState.banners && searchState.banners.length > 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 2,
                  position: 'fixed',
                  bottom: '2px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 1000,
                  backgroundColor: 'transparent',
                  padding: 1,
                  // width: 'auto',
                  width: 'calc(100% - 20px)',
                  height: 'auto',
                }}
              >
                {searchState.banners.map((banner, index) => {

                  return (
                    <a
                      key={index}
                      href={banner.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      <img
                        src={`data:image;base64,${banner.image}`}
                        alt={`Publicidade ${index + 1}`}
                        style={{
                          width: '1230px',
                          height: '150px',
                        }}
                      />
                    </a>
                  );
                })
                }
              </Box>
            )
              :
              (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 2,
                    position: 'fixed',
                    bottom: '2px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1000,
                    backgroundColor: 'transparent',
                    padding: 1,
                    // width: 'auto',
                    width: 'calc(100% - 20px)',
                    height: 'auto',
                  }}
                >


                  <a
                    key={0}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <img
                      src={'./images/PESQUISA.jpeg'}
                      alt={`Publicidade `}
                      style={{
                        width: '1230px',
                        height: '150px',
                      }}
                    />
                  </a>
                </Box>
              )
          )}
        </>
      )}
    </>
  )

  return (
    <BasePage loading={loading} theme={theme}>
      {page}
    </BasePage>
  );
};

export default Search;
