import { useState } from 'react';
import { CssBaseline, ThemeProvider, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';

import Header from '../components/Header.jsx';
import Sidebar from '../components/Sidebar.jsx';
import Footer from '../components/Footer.jsx';

import AppRoutes from '../routes/AppRoutes.jsx';

import theme from './theme.jsx';


function MainLayout() {
  const [mobileOpen, setMobileOpen] = useState(false);


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>

      <Box sx={{ display: 'flex', }}>
        <CssBaseline />
        <Header handleDrawerToggle={handleDrawerToggle} />
        <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - 240px)` },
            mt: 'auto', // Isso empurra o conteúdo para cima
            mb: 6, // Margem na parte inferior do conteúdo principal
          }}

        >
          <Toolbar />

          <AppRoutes />

          <Footer />

        </Box>
      </Box >
    </ThemeProvider>
  );
}

export default MainLayout;

