import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';

import BasePage from '../../../../components/page_base/PageBase';

import { countProfessionalsByProfessions } from '../../professional.reducer';

import theme from '../theme';

const CountProfessionalsByProfession = () => {
    const professionalState = useSelector((state) => state.professionalState);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(countProfessionalsByProfessions()).then(() => {
            setLoading(false);
        });
    }, [dispatch]);

    let totalRecords = 0;
    const data = professionalState.professionalsByProfessions && professionalState.professionalsByProfessions.map((item) => ({
        nomeDaProfissao: item.professionName || '',
        quantidade: item.quantity || 0,
    }));

    // Calculando a soma das quantidades
    totalRecords = data.reduce((accumulator, currentValue) => accumulator + currentValue.quantidade, 0);

    const columnNames = data.length > 0 ? Object.keys(data[0]) : [];

    const page = (
        <>
            {professionalState.professionalsByProfessions && professionalState.professionalsByProfessions.length > 0 && (
                <>
                    <Typography variant="h5" align="center" gutterBottom>
                        Profissionais por Profissão ({totalRecords})
                    </Typography>
                    <Box boxShadow={3} borderRadius={5} p={2} mb={2} style={{ backgroundColor: 'white' }}>
                        <Typography variant="body1">
                            Exibindo a contagem de profissionais agrupados por profissão.
                        </Typography>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>
                                        Nome da Profissão
                                    </TableCell>
                                    <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>
                                        Quantidade
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={columnNames.length} align="center">Nenhum registro encontrado</TableCell>
                                    </TableRow>
                                ) : (
                                    data.map((row, index) => (
                                        <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                            <TableCell>{row.nomeDaProfissao}</TableCell>
                                            <TableCell>{row.quantidade}</TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </>
    );

    return (
        <BasePage theme={theme} loading={loading}>
            {page}
        </BasePage>
    );
}

export default CountProfessionalsByProfession;
