import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Paper,
    Typography,
    Pagination
} from "@mui/material";

import BasePage from "../../../../components/page_base/PageBase";

import { loadAllBudgets, setCurrentPage } from "../../budget.reducer";

import { parseDateString } from "../../../utils/Utils";

import theme from './theme';
import HeadPage from "../../../../components/title/Title";



const ADMListAllBudgets = () => {
    const dispatch = useDispatch();

    const budgetState = useSelector((state) => state.budgetState);

    const [loading, setLoading] = useState(true);

    // Simulando uma chamada para buscar orçamentos
    useEffect(() => {
        setLoading(false);
        dispatch(loadAllBudgets(budgetState.currentPage, budgetState.pageSize)).then(() => {
            setLoading(false);
        })
    }, [dispatch, budgetState.currentPage, budgetState.pageSize]);

    const handleSearch = (event) => {
        dispatch(setCurrentPage(0));
    };

    const handlePageChange = (event, value) => {
        dispatch(setCurrentPage(value));
    };

    const pageContent = (
        <>
            <HeadPage title={`Orçamentos cadastrados `} />

            <TextField
                label="Pesquisar"
                variant="outlined"
                fullWidth
                onChange={handleSearch}
                margin="normal"
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead variant="head" style={{ backgroundColor: '#1976d2', color: 'white' }}>
                        <TableRow>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>ID</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Data</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Nome</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>email</TableCell>
                            {/* <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Telefone</TableCell> */}
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Descrição</TableCell>
                            <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Profissão</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {budgetState.budgets && budgetState.budgets.map((budget, index) => (
                            <TableRow key={budget.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                <TableCell>{budget.id}</TableCell>
                                <TableCell>
                                    {budget.createdAt ? (
                                        <Typography variant="body1" style={{ fontWeight: 'bold', color: 'red' }}>
                                            {parseDateString(budget.createdAt)?.toLocaleDateString("pt-BR")}
                                        </Typography>
                                    ) : (
                                        <Typography variant="body1" style={{ fontWeight: 'bold', color: 'red' }}>
                                            Data Inválida
                                        </Typography>
                                    )}
                                </TableCell>
                                <TableCell>{budget.name}</TableCell>
                                <TableCell>{budget.email}</TableCell>
                                {/* <TableCell>{budget.telephone}</TableCell> */}
                                <TableCell>{budget.description}</TableCell>
                                <TableCell>
                                    {budget.professionals.map(professional =>
                                        professional.professions.map(profession => profession.name).join(", ")
                                    ).join(", ")}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={Math.ceil(budgetState.total / budgetState.pageSize)}
                page={budgetState.currentPage}
                onChange={handlePageChange}
                color="primary"
                style={{ marginTop: 16, marginBottom: 16 }}
            />
        </>
    );

    return (
        <BasePage theme={theme} loading={loading}>
            {loading ? <Typography>Carregando...</Typography> : pageContent}
        </BasePage>
    );
}

export default ADMListAllBudgets;
