import { createSlice } from "@reduxjs/toolkit";
import storage from "../../store/storage";
import authorization from "../../services/authorization";


const initialState = {
    credencials: {
        email: "",
        password: "",
    },
    errorMessage: "",
    successMessage: "",
};

const LoginSlice = createSlice({
    name: "loginState",
    initialState,
    reducers: {
        login: (state, { payload }) => {
            const credencials = {
                email: payload.login,
            };
            storage.setToken(payload.token);
            storage.setUser(payload.user);
            return { ...state, credencials, errorMessage: "" }
        },
        setErrorMessage(state, { payload }) {
            const errorMessage = payload;
            return { ...state, errorMessage };
        },
        setSuccessMessage(state, { payload }) {
            const successMessage = payload;
            return { ...state, successMessage };
        },
    }
});


export const { login, setErrorMessage, setSuccessMessage } = LoginSlice.actions;

export const signIn = (credential) => async (dispatch) => {
    try {
        await authorization
            .login(credential)
            .then((response) => {
                dispatch(login(response))
                window.location.pathname = '/';
            });
    } catch (error) {
        dispatch(setErrorMessage("credenciais inválidas"));
        console.log("Erro ao Logar => ", error);
    }
};

export const sendEmail = (email) => async (dispatch) => {
    try {
        // fazer uma pesquisa se existe na base de dados o email 
        const URI = `/publica/user/send-mail`;
        await fetch(URI, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email })
        })
            .then((response) => {
                if (response.ok) {
                    // A resposta foi bem-sucedida
                    const data = response.json();
                    // console.log({ data });
                    dispatch(setErrorMessage(''));
                    dispatch(setSuccessMessage("Enviamos um e-mail com as instruções para recuperar a senha."));
                } else {
                    // A resposta contém um erro
                    const errorData = response.json();
                    // console.log({ errorData });
                    dispatch(setErrorMessage(errorData.error || "Ops, Erro ao buscar o e-mail na base de dados."));
                    dispatch(setSuccessMessage(''));
                }
            });
    } catch (error) {
        dispatch(setErrorMessage("Email não encontado na base de dados"));
        console.log("Erro ao buscar email => ", error);
    }
}

export const confirmPassword = (token, newPassword) => async (dispatch) => {
    try {
        const data = {
            email: token,
            password: newPassword,
        }
        //  URI
        const URI = `/publica/reset/password`;
        await fetch(URI, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                // A resposta foi bem-sucedida
                if (response.ok) {
                    // console.log({ data });
                    dispatch(setErrorMessage(''));
                    dispatch(setSuccessMessage("Senha redefinida com sucesso!."));
                }

            })
            .catch((e) => {
                console.log(e);
                dispatch(setErrorMessage('Erro ao Redefinir a senha'));
            })



    } catch (error) {
        dispatch(setErrorMessage("Ops!, erro ao confirmar a senha"));
        console.log("Erro ao confirmar senha => ", error);
    }
}


export default LoginSlice.reducer