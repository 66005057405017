import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Close as CloseIcon, AttachMoney as AttachMoneyIcon } from '@mui/icons-material';
import { Modal, Box, Typography, TextField, Button, FormControl, InputLabel, IconButton, Grid } from '@mui/material';

import utils from '../../utils';

import MakeMessage from '../../../components/makeMessage/MakeMessage';
import {
    sendBudget,
    setErrorMessage,
    setSuccessMessage,
} from '../search.reducer';




const QuoteModal = ({ open, professionalsId, onClose, errorMessage, successMessage }) => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [telephone, setTelephone] = useState('');
    const [description, setDescription] = useState('');
    const [showError, setShowError] = useState(false);


    // Função para verificar palavras censuradas na descrição
    const checkCensoredWords = (text) => {
        const words = text.toLowerCase().split(/\s+/);
        return words.some(word => utils.censoredWords.includes(word));
    };

    const handleTelephoneChange = (event) => {
        setTelephone(utils.formatPhoneNumber(event.target.value));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Verificar se a descrição contém palavras censuradas
        if (checkCensoredWords(description)) {
            setShowError(true);
        } else {
            // Lógica para enviar o orçamento
            const budget = {
                name,
                email,
                telephone,
                description,
                professionalsId,
            };

            try {
                await dispatch(sendBudget(budget));
                clearFields();
            } catch (error) {
                console.error("Failed to send budget:", error);
            }
        }
    };

    const onCloseMessage = () => {
        dispatch(setErrorMessage(""));
        dispatch(setSuccessMessage(""));
    };

    const clearFields = () => {
        setName('');
        setEmail('');
        setTelephone('');
        setDescription('');
        setShowError(false);
        professionalsId = null;
    }

    useEffect(() => {
        if (open) {
            clearFields();
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%', // Largura ajustada de forma responsiva
                maxWidth: 600, // Largura máxima para garantir legibilidade
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={8}>
                        <Typography variant="h5" id="modal-title" gutterBottom>
                            Formulário de Orçamento
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton aria-label="Fechar" onClick={onClose} color="error">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <Typography variant="body1" color="error" gutterBottom>
                    O orçamento estará ativo por 15 dias.
                </Typography>


                {/* Mensagens de erro e sucesso */}
                {errorMessage && (
                    <MakeMessage
                        typeMessage="error"
                        message={errorMessage}
                        onClose={onCloseMessage}
                    />
                )}

                {successMessage && (
                    <MakeMessage
                        typeMessage="success"
                        message={successMessage}
                        onClose={onCloseMessage}
                    />
                )}
                <form onSubmit={handleSubmit}>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel htmlFor="name" shrink={!!name}>Nome:</InputLabel>
                        <TextField
                            id="name"
                            name="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            sx={{ mt: 1 }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel htmlFor="email" shrink={!!email}>E-mail:</InputLabel>
                        <TextField
                            id="email"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            sx={{ mt: 1 }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel htmlFor="telephone" shrink={!!telephone}>Telefone:</InputLabel>
                        <TextField
                            id="telephone"
                            name="telephone"
                            type="tel"
                            value={telephone}
                            onChange={handleTelephoneChange}
                            inputProps={{ maxLength: 15 }}
                            required

                            sx={{ mt: 1 }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel htmlFor="description" shrink={!!description}>Descreva com detalhes o serviço:</InputLabel>
                        <TextField
                            id="description"
                            name="description"
                            multiline
                            rows={4}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            error={showError}
                            helperText={showError && "Descrição contém palavras inapropriadas!."}
                            required
                            sx={{ mt: 1 }}
                        />
                    </FormControl>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AttachMoneyIcon />}
                        type="submit"
                        sx={{ mt: 2 }}
                    >
                        Enviar Orçamento
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default QuoteModal;


