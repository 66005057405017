import { createSlice } from "@reduxjs/toolkit";

import utils from '../utils';
import { URL_PUBLICA } from "../../constants/constants";


const initialState = {
    total: 0,
    state: '',
    states: utils.getStates(),
    cityName: '',
    pageSize: 100,
    currentPage: 1,
    errorMessage: "",
    successMessage: "",
    professionName: '',
    cities: [],
    banners: [],
    professions: [],
    professionals: [],
}

const SearchSlice = createSlice({
    name: 'searchProfessionalsState',
    initialState,
    reducers: {
        setCities: (state, { payload }) => {
            const cities = payload;
            return { ...state, cities }
        },
        setBanners: (state, { payload }) => {
            const banners = payload;
            return { ...state, banners }
        },
        setProfessions: (state, { payload }) => {
            const professions = payload;
            return { ...state, professions }
        },
        setProfessionals: (state, { payload }) => {
            const professionals = payload;
            return { ...state, professionals }
        },
        setErrorMessage(state, { payload }) {
            const errorMessage = payload;
            return { ...state, errorMessage };
        },
        setSuccessMessage(state, { payload }) {
            const successMessage = payload;
            return { ...state, successMessage };
        },
        setCurrentPage: (state, { payload }) => {
            const currentPage = payload;
            return { ...state, currentPage }
        },
        setTotalProfessionals: (state, { payload }) => {
            const total = payload;
            return { ...state, total }
        },
    }
});

export const {
    setCities,
    setBanners,
    setProfessions,
    setCurrentPage,
    setErrorMessage,
    setProfessionals,
    setSuccessMessage,
    setTotalProfessionals,
} = SearchSlice.actions;

export const loadProfessionals = (cityId, professionId, page = 1, pageSize = 100) => (dispatch) => {
    try {
        const offset = (page - 1) * pageSize;
        const data = {
            cityId,
            professionId,
            limit: pageSize,
            offset: offset,
        }

        return fetch(`${URL_PUBLICA}/search-all-professionals-and-city-and-profession`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log({ data });
                dispatch(setTotalProfessionals(data.total));
                dispatch(setProfessionals(data.profissionais));
            })
            .catch((error) => {
                console.log({ error })
                dispatch(setTotalProfessionals(0));
                dispatch(setProfessionals([]));
            });
    } catch (error) {
        console.log({ error })
    }
}

export const loadProfessionalsByNameAndCityAndProfession = (data) => (dispatch) => {
    try {
        const offset = (data.currentPage - 1) * data.pageSize;
        const contentData = {
            name: data.searchTerm,
            cityId: data.cityId,
            professionId: data.professionId,
            limit: data.pageSize,
            offset: offset,
            pageSize: data.pageSize
        };

        return fetch(`${URL_PUBLICA}/search-professionals-by-name-and-city-and-profession`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contentData)
        })
            .then((response) => response.json())
            .then((data) => {
                console.log({ data });
                dispatch(setTotalProfessionals(data.length)); // Ajuste para o total de profissionais retornados
                dispatch(setProfessionals(data)); // Ajuste para a lista de profissionais retornados
            })
            .catch((error) => {
                console.log({ error });
                dispatch(setTotalProfessionals(0));
                dispatch(setProfessionals([]));
                dispatch(setErrorMessage("Ops, Houve um erro ao pesquisar os profissionais."));
            });
    } catch (error) {
        console.log({ error });
    }
};



export const loadProfessions = () => (dispatch) => {
    try {
        return fetch(`${URL_PUBLICA}/professions/all`, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(setProfessions(data));
            })
            .catch((error) => {
                console.log({ error })
            });
    } catch (error) {
        console.log({ error })
        dispatch(setProfessions([]));
    }

}
export const loadCities = (state) => (dispatch) => {
    try {
        const data = {
            uf: state
        }
        return fetch(`${URL_PUBLICA}/cities-by-state`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(setCities(data));
            })
            .catch((error) => {
                console.log({ error });
                dispatch(setCities([]));
            });
    } catch (error) {
        console.log({ error })
    }
}

export const clearProfessions = () => (dispatch) => {
    dispatch(setProfessionals([]))
}

export const sendBudget = (budget) => (dispatch) => {

    if (budget != null) {
        return fetch(`${URL_PUBLICA}/save/budget`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(budget)
        })
            .then((response) => {
                // console.log(response)
                dispatch(setSuccessMessage("Orçamento enviado com sucesso."));
            })
            .catch((error) => {
                console.log({ error })
                dispatch(setErrorMessage("Ops, enviar o orçamento!."));
            });

    } else {
        dispatch(setErrorMessage("Ops, ao tentar enviar o orçamento!."));
    }

}


export const findBannerByCityAndProfession = (cityId, professionId) => (dispatch) => {
    if (cityId != null && professionId != null) {
        const bodyData = {
            cityId,
            professionId,
        }
        return fetch(`${URL_PUBLICA}/find-banner-city-and-profession`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(bodyData)
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log({ data })
                dispatch(setBanners(data))
            })
            .catch((error) => {
                console.log({ error })
                dispatch(setErrorMessage("Ops, enviar o orçamento!."));
            });

    } else {
        dispatch(setErrorMessage("Ops, ao tentar enviar o orçamento!."));
    }

}

export const clearBanners = () => (dispatch) => {
    dispatch(setBanners([]))
}


export default SearchSlice.reducer