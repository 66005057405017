// import { Box, Button, Typography, Grid } from '@mui/material';
// import { Link } from 'react-router-dom';
// import { ThemeProvider } from '@mui/material/styles';
// import theme from './theme';

// const ImageWithText = ({ imageUrl, title, text, text_botton, link, bannerUrl }) => {
//     return (
//         <ThemeProvider theme={theme}>
//             <Box sx={{ ...theme.components.MuiBox.styleOverrides.root }}>
//                 <Grid container spacing={2}>
//                     <Grid item xs={12} sm={6} order={{ xs: 2, sm: 2 }} style={{ marginBottom: '20px' }}>
//                         <img src={imageUrl} alt="Imagem" style={{ width: '100%', height: 'auto' }} />
//                     </Grid>
//                     <Grid item xs={12} sm={6} order={{ xs: 1, sm: 1 }}>
//                         <Typography variant="h5">
//                             {title}
//                         </Typography>
//                         <br />
//                         <Typography variant="body1">
//                             {text}
//                         </Typography>
//                         <Link to={link} style={{ textDecoration: 'none' }}>
//                             <Button variant="contained" color="primary" style={{ marginTop: '20px' }}>
//                                 {text_botton}
//                             </Button>
//                         </Link>
//                     </Grid>
//                     <Grid item xs={12} order={3} style={{ marginTop: '20px' }}>
//                         <img src={bannerUrl} alt="Banner" style={{ width: '100%', height: 'auto' }} />
//                     </Grid>
//                 </Grid>
//             </Box>
//         </ThemeProvider>
//     );
// };

// export default ImageWithText;


import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

const ImageWithText = ({ imageUrl, title, text, text_botton, link }) => {
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ ...theme.components.MuiBox.styleOverrides.root }}>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flex: 1, display: { xs: 'none', sm: 'block' } }} style={{ marginRight: '20px' }}>
                        <img src={imageUrl} alt="Imagem" style={{ width: '100%', height: 'auto' }} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography variant="h5">
                            {title}
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            {text}
                        </Typography>
                        <Link to={link} style={{ textDecoration: 'none' }}>
                            <Button variant="contained" color="primary" style={{ marginTop: '20px' }}>
                                {text_botton}
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default ImageWithText;
