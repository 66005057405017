import { createSlice } from "@reduxjs/toolkit";
import { URL_PUBLICA } from "../../constants/constants";

const initialState = {
    professions: [],
}

const ProfessionsSlice = createSlice({
    name: 'professionsState',
    initialState,
    reducers: {
        setProfessions: (state, { payload }) => {
            const professions = payload;
            return { ...state, professions }
        }
    }
});

export const { setProfessions } = ProfessionsSlice.actions;

export const loadProfessionsWithCount = () => (dispatch) => {

    const URI = `${URL_PUBLICA}/find/professions-with-count`;
    return fetch(URI, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            // console.log('Data:', data);
            dispatch(setProfessions(data));
        })
        .catch((error) => {
            console.log('Error:', error);
        });

}

export default ProfessionsSlice.reducer;

