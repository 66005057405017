import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import theme from './theme';
import BasePage from '../../../components/page_base/PageBase';
import MakeMessage from '../../../components/makeMessage/MakeMessage';

import { sendEmail, setErrorMessage, setSuccessMessage } from '../login.reducer';


const PasswordResetPage = () => {
    const dispatch = useDispatch();

    const loginState = useSelector((state) => state.loginState);

    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, [loading]);

    const onChangeEmailHandler = (e) => {
        setEmail(e.target.value);
    };

    const onCloseMessage = () => {
        dispatch(setErrorMessage(""));
        dispatch(setSuccessMessage(""));
    };

    const onRequestPasswordResetHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        dispatch(sendEmail(email)).then(() => {
            setLoading(false);
        });
    };

    const page = (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '400px',
                    p: 2,
                }}
            >
                <Box sx={{ mt: 4, mb: 12, textAlign: 'center' }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Recuperar Senha
                    </Typography>

                    {loginState.errorMessage && (
                        <MakeMessage
                            typeMessage="error"
                            message={loginState.errorMessage}
                            onClose={onCloseMessage}
                        ></MakeMessage>
                    )}

                    {loginState.successMessage && (
                        <MakeMessage
                            typeMessage="success"
                            message={loginState.successMessage}
                            onClose={onCloseMessage}
                        ></MakeMessage>
                    )}

                    <form>
                        <TextField
                            id="email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            autoFocus
                            required
                            onChange={onChangeEmailHandler}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            sx={{ mt: 2 }}
                            onClick={onRequestPasswordResetHandler}
                        >
                            Recuperar
                        </Button>
                    </form>
                </Box>
            </Box>
        </Box>
    );

    return (
        <BasePage
            theme={theme}
            loading={loading}
        >
            {page}
        </BasePage>
    );
};

export default PasswordResetPage;
