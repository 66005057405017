import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";

import theme from './theme';

import BasePage from '../../../components/page_base/PageBase';
import MakeMessage from '../../../components/makeMessage/MakeMessage';

import { signIn, setErrorMessage } from '../login.reducer';


const LoginPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const loginState = useSelector((state) => state.loginState);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        setLoading(false);
    }, [loading]);

    const onChangeEmailHandler = (e) => {
        setEmail(e.target.value);
    };
    const onChangePasswordHandler = (e) => {
        setPassword(e.target.value);
    };

    const handlerClickShowPassword = () => {
        if (!showPassword) {
            setShowPassword(true);
        } else {
            setShowPassword(false);
        }
    };

    const onCloseMessage = () => {
        dispatch(setErrorMessage(""));
    };


    const onSignHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        const credential = {
            email,
            password,
        };
        dispatch(signIn(credential)).then(() => {
            setLoading(false);
        });
    };

    const page = (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '400px',
                    p: 2,
                }}
            >

                <Box sx={{ mt: 4, mb: 12, textAlign: 'center' }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Login
                    </Typography>

                    {loginState.errorMessage && (
                        <MakeMessage
                            typeMessage="error"
                            message={loginState.errorMessage}
                            onClose={onCloseMessage}
                        ></MakeMessage>
                    )}

                    <form>
                        <TextField
                            id="email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            autoFocus
                            required
                            onChange={onChangeEmailHandler}

                        />
                        <OutlinedInput
                            id="password"
                            name="password"
                            margin="dense"
                            required
                            fullWidth
                            label="Senha"
                            type={showPassword ? "text" : "password"}
                            autoComplete="current-password"
                            onChange={onChangePasswordHandler}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handlerClickShowPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <Box sx={{ textAlign: 'right', mt: 1 }}>
                            <Link to="/recuperar-senha" style={{ textDecoration: 'none' }}>
                                <Typography variant="body2" color="primary">
                                    Esqueci a senha
                                </Typography>
                            </Link>
                        </Box>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            sx={{ mt: 2 }}
                            onClick={onSignHandler}
                        >
                            Login
                        </Button>
                    </form>
                </Box>
            </Box>
        </Box>

    )

    return (
        <BasePage
            theme={theme}
            loading={loading}
        >
            {page}
        </BasePage>
    )
};

export default LoginPage;
