import { Modal, Box, Typography, Button, useMediaQuery, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

const PrivacyPolicyModal = ({ open, onClose }) => {

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >

            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%',
                maxWidth: '100%',
                maxHeight: '80vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography variant="h3" component="h2" id="modal-title" gutterBottom>
                    Política de Privacidade
                </Typography>
                <Box sx={{ mb: 2 }}>

                    <Typography variant="body1" paragraph>Sua privacidade é muito importante para nós! Esta Política de Privacidade esclarece como é feito o tratamento dos seus dados pessoais a partir da nossa
                        ferramenta.
                        Assim, prezamos pela transparência entre nossa equipe e você, nosso usuário, fortalecendo nossa parceria e relação de confiança. Nesse sentido, gostaríamos
                        de
                        tranquilizá-los, pois estamos totalmente adequados à Lei Geral de Proteção de Dados do Brasil – LGPD (Lei n° 13.709/2018), conforme podem conferir os termos
                        abaixo
                        estipulados.</Typography>

                    <Typography variant="h5">Quem somos?</Typography>
                    <Typography variant="body1" paragraph>Mais que um site, a C + B é uma plataforma online que busca reunir prestadores de serviços e clientes de uma forma rápida e barata, facilitando o encontro
                        entre
                        profissional e sua obra.</Typography>
                    <Typography variant="body1" paragraph>O nosso contato é realizado por meio do e-mail: <a href="mailto:atendimento@construirmaisbarato.com.br">atendimento@construirmaisbarato.com.br</a></Typography>
                    <Typography variant="body1" paragraph>Nós temos também um responsável pela proteção de dados, portanto, quaisquer dúvidas ou solicitações sobre o uso de seus dados pessoais devem ser encaminhadas
                        para o
                        nosso encarregado de dados:</Typography>
                    <Typography variant="body1" paragraph>
                        Jairo Assis{' '}
                        <Link href="mailto:lgpd@construirmaisbarato.com.br">lgpd@construirmaisbarato.com.br</Link>{' '}
                        (14) 98835-0791
                    </Typography>

                    <Typography variant="h5">COMO USAMOS OS SEUS DADOS:</Typography>

                    <List>
                        <ListItem>
                            <ListItemText primary="Nosso site pode ser utilizado para áreas como construção, pintura, elétrica e reparos hidráulicos." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Podem oferecer serviços em nosso site profissionais com CNPJ, MEI ou autônomos." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Os usuários (cliente final) poderão ser pessoas jurídicas ou físicas." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Ao fazer o cadastro em nossa plataforma (site/aplicativo), coletaremos algumas informações que serão fornecidas exclusivamente pelo usuário. Todavia, esclarecemos que essas informações são basicamente cadastrais, como as seguintes informações: nome, e-mail, CPF, endereço e telefone. Quando solicitado o endereço, este se refere ao local da prestação de serviço a ser realizado." />
                        </ListItem>
                    </List>

                    <Typography variant="body1" paragraph>Menores de idade não poderão utilizar nossos serviços. Ressaltamos que a exclusão dos dados de nossa ferramenta é perfeitamente possível.</Typography>

                    <Typography variant="body1" paragraph>Usamos essas informações exclusivamente para a funcionalidade de nosso sistema. Também podemos lhe enviar e-mails. Faremos isso com base em nosso interesse
                        legítimo em
                        fornecer informações precisas e um serviço de qualidade. Caso não queira receber nossos e-mails, basta realizar o descadastramento em nosso site.</Typography>

                    <Typography variant="body1" paragraph>Suas informações são armazenadas em nosso servidor e será tratada apenas em decorrência da nossa prestação de serviços. Não comercial </Typography>

                    <Typography variant="h5">COOKIES</Typography>
                    <Typography variant="body1" paragraph>Quando você usa nosso site para navegar em nossos serviços, vários cookies são usados por nós e por terceiros para permitir que o site funcione, para coletar
                        informações
                        úteis sobre os visitantes, ajudando a tornar sua experiência de usuário melhor.</Typography>
                    <Typography variant="body1" paragraph>Alguns dos cookies que usamos são estritamente necessários para o funcionamento do nosso site, e não pedimos o seu consentimento para colocá-los no seu
                        computador. No
                        entanto, para os cookies que são úteis, mas não estritamente necessários, pediremos sempre o seu consentimento antes de os colocar.</Typography>

                    <Typography variant="h5">Do Compartilhamento</Typography>
                    <Typography variant="body1" paragraph>Seus dados são armazenados em nosso banco de dados, mas não serão compartilhados com terceiros, a não ser nos casos previstos em Lei.</Typography>

                    <Typography variant="h5">Dos Serviços</Typography>
                    <Typography variant="body1" paragraph>A função da nossa plataforma é facilitar o encontro entre profissionais e clientes, meramente informativo e consultivo, no estilo “páginas amarelas” das
                        listas
                        telefônicas. Toda e qualquer negociação realizada entre as partes é de responsabilidade delas. Nosso site NÃO se responsabiliza por defeitos na prestação
                        dos serviços
                        contratados pelo usuário.</Typography>

                    <Typography variant="h5">Do armazenamento e segurança</Typography>
                    <Typography variant="body1" paragraph>Utilizamos técnicas e softwares seguros e renomados para o armazenamento de todas as informações que transitam pelo site. Assim, garantimos a utilização de
                        medidas
                        técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda,
                        alteração,
                        comunicação ou difusão de seus dados.</Typography>

                    <Typography variant="h5">Seus direitos como titular de dados</Typography>
                    <Typography variant="body1" paragraph>Por lei, qualquer indivíduo poderá nos perguntar quais são as informações que temos sobre ele em nosso banco de dados, além de ser garantido o direito de
                        correção, se as
                        informações estiverem imprecisas, por meio do e-mail <a href="mailto:lgpd@construirmaisbarato.com.br">lgpd@construirmaisbarato.com.br</a>. Se solicitarmos o
                        seu
                        consentimento para processar seus dados, você poderá retirar esse consentimento a qualquer momento, bem como solicitar a exclusão de dados. Caso queira
                        enviar uma
                        solicitação sobre a utilização de seus dados pessoais (informações, correções e exclusão), use o endereço eletrônico fornecido nesta política.</Typography>

                    <Typography variant="h5">Atualizações para esta política de privacidade</Typography>
                    <Typography variant="body1" paragraph>Revisamos regularmente e, se apropriado, atualizaremos esta política de privacidade de tempos em tempos, e conforme nossos serviços e uso de dados sejam
                        alterados. Se,
                        eventualmente, usarmos seus dados pessoais de uma forma que não identificada ou descrita anteriormente, entraremos em contato para fornecer informações
                        sobre isso e, se
                        necessário, solicitar o seu consentimento.</Typography>


                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={onClose} variant="contained" color="primary" sx={{ mr: 2 }}>
                            Fechar
                        </Button>

                    </Box>
                </Box>
            </Box>

        </Modal >
    );
};

export default PrivacyPolicyModal;
