import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Footer = () => {
    return (
        <Box component="footer" sx={{ p: 2, backgroundColor: '#1976d2', color: 'white', textAlign: 'center' }}>
            <Typography variant="body1">
                © 2024 Construir mais barato. Todos os direitos reservados.
            </Typography>
        </Box>
    );
};

export default Footer;
