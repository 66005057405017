import React from "react";
import { Box, Typography } from "@mui/material";

const ErrorPage = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Typography variant="h1" component="h1">404</Typography>
      <Typography variant="h6" component="p">Página não encontrada</Typography>
    </Box>
  );
};

export default ErrorPage;
