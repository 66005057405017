import { createSlice } from "@reduxjs/toolkit"
import api from '../../services/api.service';
import { URL_API, URL_PUBLICA } from "../../constants/constants";
import utils from "../utils";



const initialState = {
    total: 0,
    states: utils.getStates(),
    pageSize: 50,
    currentPage: 1,
    professional: null,
    errorMessage: "",
    successMessage: "",
    cities: [],
    professions: [],
    professionals: [],
    lastProfessionals: [],
    professionalsByState: [],
    professionalsByProfessions: [],
    quantityProfessionalsByCity: [],
}

const ProfessionalSlice = createSlice({
    name: 'professionalState',
    initialState,
    reducers: {
        setCities: (state, { payload }) => {
            const cities = payload;
            return { ...state, cities }
        },
        setProfessions: (state, { payload }) => {
            const professions = payload;
            return { ...state, professions }
        },
        setProfessional: (state, { payload }) => {
            const professional = payload;
            return { ...state, professional }
        },
        setCurrentPage: (state, { payload }) => {
            const currentPage = payload;
            return { ...state, currentPage }
        },
        setTotalProfessionals: (state, { payload }) => {
            const total = payload;
            return { ...state, total }
        },
        setLastProfessionals: (state, { payload }) => {
            const lastProfessionals = payload;
            return { ...state, lastProfessionals }
        },
        setProfessionals: (state, { payload }) => {
            const professionals = payload;
            return { ...state, professionals }
        },
        setProfessionalsByProfessions: (state, { payload }) => {
            const professionalsByProfessions = payload;
            return { ...state, professionalsByProfessions }
        },
        setQuantityProfessionalsByCity: (state, { payload }) => {
            const quantityProfessionalsByCity = payload;
            return { ...state, quantityProfessionalsByCity }
        },
        setProfessionalsByState: (state, { payload }) => {
            const professionalsByState = payload;
            return { ...state, professionalsByState }
        },
        setErrorMessage(state, { payload }) {
            const errorMessage = payload;
            return { ...state, errorMessage };
        },
        setSuccessMessage(state, { payload }) {
            const successMessage = payload;
            return { ...state, successMessage };
        },
        removeProfessional(state, { payload }) {
            const professionals = state.professionals.filter(professional => professional.oid !== payload);
            return { ...state, professionals }
        },

    }
});

export const {
    setCities,
    setCurrentPage,
    setProfessions,
    setProfessional,
    setErrorMessage,
    setProfessionals,
    setSuccessMessage,
    removeProfessional,
    setLastProfessionals,
    setTotalProfessionals,
    setProfessionalsByState,
    setProfessionalsByProfessions,
    setQuantityProfessionalsByCity,
} = ProfessionalSlice.actions;

export const save = ({ id = null, name, email, telephone, cityId, lgpdAceito, password, professionIds }) => (dispatch, getState) => {
    try {

        // console.log("ID do usuário => ", id);

        const state = getState();
        const professional = state.professionalState.professional;

        const intID = professional ? professional.oid : null;
        // console.log("ID do profissional => ", intID);
        const data = {
            oid: intID,
            name,
            email,
            telephone,
            cityId,
            lgpdAceito,
            password,
            professionIds,
        }


        if (intID) { //atualizar

            const URI = `${URL_API}/professional`;
            return fetch(URI, {
                method: 'POST',
                headers: api.getHeaders(),
                body: JSON.stringify(data)
            })
                .then((response) => response.json())
                .then((data) => {
                    dispatch(setProfessional(data));
                    dispatch(setSuccessMessage("Cadastro realizado com sucesso."))
                })
                .catch((error) => {
                    console.log({ error })
                    dispatch(setErrorMessage("Ops, Houve um erro ao realizar o cadastro."));
                });

        } else {

            const URI = `${URL_PUBLICA}/save/professional`;
            return fetch(URI, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })
                .then((response) => response.json())
                .then((data) => {
                    dispatch(setProfessional(data));
                    dispatch(setSuccessMessage("Cadastro realizado com sucesso."))
                })
                .catch((error) => {
                    console.log({ error })
                    dispatch(setErrorMessage("Ops, Houve um erro ao realizar o cadastro."));
                });
        }


    } catch (error) {
        console.log({ error })
    }
}

export const loadProfessions = () => (dispatch) => {

    return fetch(`${URL_PUBLICA}/professions/all`, {
        method: 'GET',
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch(setProfessions(data));
        })
        .catch((error) => {
            console.log({ error })
        });

}

export const loadCities = (state) => (dispatch) => {
    try {
        const data = {
            uf: state
        }
        return fetch(`${URL_PUBLICA}/cities-by-state`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(setCities(data));

            })
    } catch (error) {
        console.log({ error })
    }
}

export const loadLastProfessionals = (quantity = 20) => (dispatch) => {

    try {
        const data = {
            quantity
        }

        const URI = `${URL_API}/last/professionals`;
        return fetch(URI, {
            method: 'POST',
            headers: api.getHeaders(),
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(setLastProfessionals(data));
            })
            .catch((error) => {
                console.log({ error });
                dispatch(setLastProfessionals([]));
                dispatch(setErrorMessage("Ops, Houve um erro ao realizar a pesquisa."));
            })
    } catch (error) {
        console.log({ error })
    }

}


export const loadAllProfessionals = (page = 1, pageSize = 50) => (dispatch) => {

    try {

        const offset = (page - 1) * pageSize;
        const URI = `${URL_API}/professionals?limit=${pageSize}&offset=${offset}`;
        return fetch(URI, {
            method: 'GET',
            headers: api.getHeaders(),
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log({ data });
                dispatch(setTotalProfessionals(data.total));
                dispatch(setProfessionals(data.profissionais));
            })
            .catch((error) => {
                console.log({ error })
                dispatch(setTotalProfessionals(0));
                dispatch(setProfessionals([]));
                dispatch(setErrorMessage("Ops, Houve um erro ao realizar a pesquisa."));
            })
    } catch (error) {
        console.log({ error })
    }
}

export const loadByNameProfessionals = (name) => (dispatch) => {

    try {
        const URI = `${URL_API}/professionals/name`;

        return fetch(URI, {
            method: 'POST',
            headers: api.getHeaders(),
            body: JSON.stringify({ name })
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log({ data });
                dispatch(setTotalProfessionals(data.length));
                dispatch(setProfessionals(data));
            })
            .catch((error) => {
                console.log({ error })
                dispatch(setTotalProfessionals(0));
                dispatch(setProfessionals([]));
                dispatch(setErrorMessage("Ops, Houve um erro ao pesquisar pelo nome do profissional."));
            })
    } catch (error) {
        console.log({ error })
    }
}

export const loadProfessionalByID = (id = 0) => (dispatch) => {
    try {

        const URI = `${URL_API}/professional/${id}`;
        return fetch(URI, {
            method: 'GET',
            headers: api.getHeaders(),
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log({ data });
                dispatch(setProfessional(data));
            })
            .catch((error) => {
                console.log({ error })
                dispatch(setProfessional(null));
                dispatch(setErrorMessage("Ops, Houve um erro ao consultar o profissional."));
            })
    } catch (error) {
        console.log({ error })
    }

}

export const exportProfessionalsXLSX = () => (dispatch) => {
    const URI = `${URL_API}/export-professionals-XLSX`;
    try {
        return fetch(URI, {
            method: 'POST',
            headers: api.getHeaders(),
            responseType: 'blob'
        })
            .then((response) => {
                // console.log('Resposta recebida:', { response });
                if (!response.ok) {
                    throw new Error('Erro na resposta do servidor');
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'profissionais.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                console.error('Erro ao exportar profissionais:', error.message);
                dispatch(setErrorMessage("Ops, Houve um erro ao exportar os profissionais."));
            })

    } catch (error) {
        console.log(error);

    }
}


export const countProfessionalsByProfessions = () => (dispatch) => {

    try {
        const URI = `${URL_API}/count/professional/profession`;
        return fetch(URI, {
            method: 'POST',
            headers: api.getHeaders(),
            body: JSON.stringify({})
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(setProfessionalsByProfessions(data));
            })
            .catch((error) => {
                console.log({ error });
                dispatch(setProfessionalsByProfessions([]));
                dispatch(setErrorMessage("Ops, Houve um erro ao realizar a pesquisa."));
            })
    } catch (error) {
        console.log({ error })
    }

}

export const countProfessionalsByState = (state) => (dispatch) => {

    try {

        const URI = `${URL_API}/professionals/state`;
        return fetch(URI, {
            method: 'POST',
            headers: api.getHeaders(),
            body: JSON.stringify({ state })
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log({ data })
                dispatch(setProfessionalsByState(data));
            })
            .catch((error) => {
                console.log({ error });
                dispatch(setProfessionalsByState([]));
                dispatch(setErrorMessage("Ops, Houve um erro ao realizar a contagem dos profissionais por Estado."));
            })
    } catch (error) {
        console.log({ error })
    }

}

export const countProfessionalsByCity = (cityID) => (dispatch) => {

    try {

        const URI = `${URL_API}/count/professionals/city`;
        return fetch(URI, {
            method: 'POST',
            headers: api.getHeaders(),
            body: JSON.stringify({ cityID })
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log({ data })
                dispatch(setQuantityProfessionalsByCity(data));
            })
            .catch((error) => {
                console.log({ error });
                dispatch(setQuantityProfessionalsByCity([]));
                dispatch(setErrorMessage("Ops, Houve um erro ao realizar a contagem dos profissionais por Cidade."));
            })
    } catch (error) {
        console.log({ error })
    }

}

export const disableProfessional = (id) => (dispatch) => {
    const intID = id ? parseInt(id) : null;
    if (intID) {
        const URI = `/professional/${intID}`;
        return fetch(URI, {
            method: 'DELETE',
            headers: api.getHeaders()
        })
            .then(() => {
                dispatch(setSuccessMessage("Profissional desabilitado com sucesso."));
                dispatch(removeProfessional(id));

            })
            .catch((error) => {
                console.log({ error })
                dispatch(setErrorMessage("Ops, Houve um erro ao desabiliar o profissional."));
            });

    }
}

export default ProfessionalSlice.reducer;